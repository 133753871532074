// // import React from 'react'
// // import tech from '../assets/tech.png'
// // import creatives from "../assets/creatives.png"
// // import Livewires from "../assets/Livewires.png"
// // import pr from "../assets/pr.png"
// // import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa'; // Assuming you're using react-icons

// // function LinkTree() {
// //   return (
// //     <div className="w-full min-h-screen bg-gradient-to-r from-black via-gray-900 to-black flex flex-col items-center justify-center px-4 pb-16">
// //       <div className="text-center">
// //         <img src={Livewires} alt="Livewires" className="w-72 animate-fadeIn h-auto mb-4 md:w-96 md:mb-4" />
// //       </div>
// //       <h1 className="animate-pulse text-white text-xl md:text-3xl xl:text-4xl mb-9 font-bold">
// //         LET'S INTEND. INVENT. INSPIRE!
// //       </h1>
// //       <h2 className='animate-slideInLeft text-white text-2xl md:text-3xl xl:text-3xl mb-20 font-mono text-center px-4'>
// //         Where will your talents shine? Pick a field and join the adventure!
// //       </h2>

// //       <div className="animate-fadeIn flex flex-col md:flex-row md:gap-12 lg:gap-24">
// //         <a href="https://forms.gle/gVD9BmZxugdPkDCq6" className="animate-slideInRight bg-white/30 backdrop-blur-md border border-white/30 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg group">
// //           <div className="flex flex-col items-center transition-transform duration-300 group-hover:scale-125">
// //             <img src={tech} alt="Tech" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
// //             <button className="animate-floatUp bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //               Tech Team
// //             </button>
// //           </div>
// //         </a>
// //         <a href="https://forms.gle/gPeBS3qvU8KmjBtaA" className="animate-fadeIn bg-white/30 backdrop-blur-md border border-white/30 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg group">
// //           <div className="flex flex-col items-center transition-transform duration-300 group-hover:scale-125">
// //             <img src={creatives} alt="Creatives" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
// //             <button className="animate-floatUp bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //               Creatives Team
// //             </button>
// //           </div>
// //         </a>
// //         <a href="https://forms.gle/ZenCHGJfyP6QGVmo8" className="animate-slideInLeft bg-white/30 backdrop-blur-md border border-white/30 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg group">
// //           <div className="flex flex-col items-center transition-transform duration-300 group-hover:scale-125">
// //             <img src={pr} alt="PR" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
// //             <button className="animate-floatUp bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //               PR Team
// //             </button>
// //           </div>
// //         </a>
// //       </div>

// //       {/* Footer */}
// //       <footer className="w-full bg-slate-900 text-white flex flex-col items-center py-4 mt-16">
// //         <p className="text-lg mb-4">Follow us on</p>
// //         <div className="flex gap-4">
// //           <a href="https://www.instagram.com/live.wires_?igsh=NGx0cHllZnJ3Zmln" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
// //             <FaInstagram size={24} />
// //           </a>
// //           <a href="https://www.linkedin.com/company/livewires-srm" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
// //             <FaLinkedin size={24} />
// //           </a>
// //           <a href="https://www.facebook.com/profile.php?id=61550269514833&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
// //             <FaFacebook size={24} />
// //           </a>
// //         </div>
// //       </footer>
// //     </div>
// //   )
// // }

// // export default LinkTree


// import React from 'react';
// import tech from '../assets/tech.png';
// import creatives from "../assets/creatives.png";
// import Livewires from "../assets/Livewires.png";
// import pr from "../assets/pr.png";
// import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa'; // Assuming you're using react-icons

// function LinkTree() {
//   return (
//     <div className="w-full min-h-screen bg-gradient-to-r from-black via-gray-900 to-black flex flex-col items-center justify-center px-4 pb-16">
//       <div className="text-center">
//         <img src={Livewires} alt="Livewires" className="w-72 animate-fadeIn h-auto mb-4 md:w-96 md:mb-4" />
//       </div>
//       <h1 className="animate-pulse text-white text-xl md:text-3xl xl:text-4xl mb-9 font-bold">
//         LET'S INTEND. INVENT. INSPIRE!
//       </h1>
//       <h2 className='animate-slideInLeft text-white text-2xl md:text-3xl xl:text-3xl mb-20 font-mono text-center px-4'>
//         Where will your talents shine? Pick a field and join the adventure!
//       </h2>

//       <div className="animate-fadeIn flex flex-col md:flex-row md:gap-12 lg:gap-24">
//         <a href="https://forms.gle/gVD9BmZxugdPkDCq6" className="animate-slideInRight bg-white/30 backdrop-blur-md border border-white/30 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg group">
//           <div className="flex flex-col items-center transition-transform duration-300 group-hover:scale-125">
//             <img src={tech} alt="Tech" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
//             <button className="animate-floatUp bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
//               Tech Team
//             </button>
//             <p className='text-lg mt-4 text-yellow-300 animate-pulse shadow-sm '>Final Countdown! Ends August 3 at Noon!</p>
//           </div>
//         </a>
//         <div className="relative animate-fadeIn bg-white/20 backdrop-blur-md border border-white/20 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg opacity-90">
//           <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center rounded-lg">
//             <p className="text-white text-5xl font-bold shadow-2xl">Closed</p>
//           </div>
//           <div className="flex flex-col items-center">
//             <img src={creatives} alt="Creatives" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
//             <button className="bg-gray-400 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg cursor-not-allowed">
//               Creatives Team
//             </button>
//           </div>
//         </div>
//         <div className="relative animate-slideInLeft bg-white/20 backdrop-blur-md border border-white/20 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg opacity-850">
//           <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center rounded-lg">
//             <p className="text-white text-5xl shadow-2xl font-bold">Closed</p>
//           </div>
//           <div className="flex flex-col items-center">
//             <img src={pr} alt="PR" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
//             <button className="bg-gray-400 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg cursor-not-allowed">
//               PR Team
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Footer */}
//       <footer className="w-full bg-slate-900 text-white flex flex-col items-center py-4 mt-16">
//         <p className="text-lg mb-4">Follow us on</p>
//         <div className="flex gap-4">
//           <a href="https://www.instagram.com/live.wires_?igsh=NGx0cHllZnJ3Zmln" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
//             <FaInstagram size={24} />
//           </a>
//           <a href="https://www.linkedin.com/company/livewires-srm" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
//             <FaLinkedin size={24} />
//           </a>
//           <a href="https://www.facebook.com/profile.php?id=61550269514833&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
//             <FaFacebook size={24} />
//           </a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default LinkTree;


import React from 'react';
// import tech from '../assets/tech.png';
// import creatives from "../assets/creatives.png";
import Livewires from "../assets/Livewires.png";
// import pr from "../assets/pr.png";
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';

function LinkTree() {
  return (
    <div className="w-full min-h-screen bg-gradient-to-r from-black via-gray-900 to-black flex flex-col items-center justify-center px-4 pb-16">
      <div className="text-center">
        <img src={Livewires} alt="Livewires" className="w-72 animate-fadeIn h-auto mb-4 md:w-96 md:mb-4" />
      </div>
      <h1 className="animate-fadeIn text-yellow-400 text-2xl md:text-3xl xl:text-4xl mb-9 font-bold">
        OOPS !
      </h1>
      <h1 className='animate-pulse text-white text-2xl md:text-3xl xl:text-3xl mb-20 font-mono text-center px-4'>
      We’ve officially wrapped up recruitment for our club!   
         </h1>

      {/* <div className="animate-fadeIn flex flex-col md:flex-row md:gap-12 lg:gap-24">
        <a href="https://forms.gle/gVD9BmZxugdPkDCq6" className="animate-slideInRight bg-white/30 backdrop-blur-md border border-white/30 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg group">
          <div className="flex flex-col items-center transition-transform duration-300 group-hover:scale-125">
            <img src={tech} alt="Tech" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
            <button className="animate-floatUp bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              Tech Team
            </button>
            {timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0 ? (
              <p className='text-xl mt-4 text-yellow-300 animate-pulse shadow-sm'>
                Ends in {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s .
              </p>
            ) : (
              <div className="flex flex-col items-center">
            <img src={tech} alt="Creatives" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
            <button className="bg-gray-400 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg cursor-not-allowed">
              Tech Team
            </button>
          </div>
            )}
          </div>
        </a>
        <div className="relative animate-fadeIn bg-white/20 backdrop-blur-md border border-white/20 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg opacity-90">
          <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center rounded-lg">
            <p className="text-white text-5xl font-bold shadow-2xl">Closed</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={creatives} alt="Creatives" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
            <button className="bg-gray-400 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg cursor-not-allowed">
              Creatives Team
            </button>
          </div>
        </div>
        <div className="relative animate-slideInLeft bg-white/20 backdrop-blur-md border border-white/20 flex flex-col items-center mb-12 p-5 md:mb-0 rounded-lg opacity-850">
          <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center rounded-lg">
            <p className="text-white text-5xl shadow-2xl font-bold">Closed</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={pr} alt="PR" className="w-48 h-48 mb-4 md:w-64 md:h-64 lg:w-80 lg:h-80 rounded-lg" />
            <button className="bg-gray-400 text-white font-bold text-base md:text-lg lg:text-xl py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded-lg shadow-lg cursor-not-allowed">
              PR Team
            </button>
          </div>
        </div>
      </div> */}

      {/* Footer */}
      <footer className="w-full bg-slate-900 text-white flex flex-col items-center py-4 mt-16">
        <p className="text-lg mb-8">For more updates, Follow us on</p>
        <div className="flex  gap-24">
          <a href="https://www.instagram.com/live.wires_?igsh=NGx0cHllZnJ3Zmln" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FaInstagram size={40} />
          </a>
          <a href="https://www.linkedin.com/company/livewires-srm" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FaLinkedin size={40} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61550269514833&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FaFacebook size={40} />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default LinkTree;
